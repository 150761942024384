import React from "react";
import {withRouter} from "next/router";
import {WithRouterProps} from "next/dist/client/with-router";
import I18nMessage from "../components/i18n/I18nMessage";

import {
    withStyles,
    createStyles,
} from "@material-ui/core";
import PortalLink from "../components/PortalLink";
import Layout from "../components/Layout";
import HerettoDeployWindowObj, {ClientEvent} from "../components/HerettoDeployWindowObj";
import {getClientSideConfig} from "utils/PageUtil";

const loginStyles = createStyles((theme: any) => ({
    login: theme.login,
    loginMessageWrapper: theme.loginMessageWrapper,
    loginMessage: theme.loginMessage,
    loginEndpointWrapper: theme.loginEndpointWrapper,
    loginEndpointLink: theme.loginEndpointLink,
}));

interface ILoginProps {
    classes?: any;
    authEndpoints?: any[];
    runtimeConfig?: any;
    authRequired: boolean;
    login_uri: string;
}

class Login extends React.Component<ILoginProps & WithRouterProps> {
    constructor(props) {
        super(props);
    }
    
    static defaultProps = {
        authEndpoints: []
    }

    componentDidMount() {
        setTimeout(() => HerettoDeployWindowObj.triggerEvent(ClientEvent.pageUpdate))
    }

    componentDidUpdate() {
        setTimeout(() => HerettoDeployWindowObj.triggerEvent(ClientEvent.pageUpdate))
    }

    render() {
        const {authEndpoints} = this.props;
        return (
            <Layout
                className={"login-index"}
                hideHero={true}
                runtimeConfig={this.props.runtimeConfig}>
                <div className={this.props.classes.login}>
                    <div className={this.props.classes.loginMessageWrapper}>
                        <I18nMessage
                            className={this.props.classes.loginMessage}
                            id={"message.login"}
                        />
                    </div> 
                    <div className={this.props.classes.loginEndpointWrapper}>
                        {authEndpoints.map(({auth_uri, idp}, i) => (
                            <I18nMessage
                                key={idp + i}
                                className={`${this.props.classes.loginEndpointLink} ${idp}`}
                                Component={PortalLink}
                                href={auth_uri}
                                target={"_self"}
                                id={idp}
                            />))
                        }
                    </div>
                </div>
            </Layout>
        );
    }
}

//@ts-ignore
export default withRouter(withStyles(loginStyles, {name: "Login"})(Login)) as any;

export async function getServerSideProps({req}) {
    let {runtimeConfig = {}} = req;
    const {authStrategy, authEndpoints, automaticLoginRedirect} = runtimeConfig;
    if (!authStrategy) {
        return {
            redirect: {destination: "/"}
        }
    }
    if (authEndpoints.length == 1 && automaticLoginRedirect) {
        return {
            redirect: {destination: authEndpoints[0].auth_uri}
        }
    }
    runtimeConfig = getClientSideConfig(runtimeConfig)
    return {
        props: JSON.parse(JSON.stringify({
            authEndpoints,
            runtimeConfig
        }))
    }
}